import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

interface IntroSectionProps {
}

const IntroItem = ({ img, title, subtitle, delay }) => (
  <div
    className="md:col-span-3 col-span-2 text-center"
    data-sal="slide-up"
    data-sal-duration="1000" // changes duration of the animation (from 200 to 2000 ms)
    data-sal-delay={delay} // adds delay to the animation (from 5 to 1000 ms)
    data-sal-easing="ease"
  >
    {img}
    <p className="text-primary-pink-400 text-lg mt-2 font-medium">{title}</p>
    <p className="text-gray-600 text-sm font-light">{subtitle}</p>
  </div>
)

const IntroSection: React.FC<IntroSectionProps> = ({}) => {

  return (
    <div
      className="container mx-auto grid md:grid-cols-12 lg:gap-x-16 lg:gap-y-0 grid-cols-4 gap-4 px-4 mt-4 mb-20 bg-sprinkle bg-center bg-no-repeat bg-y-4 lg:bg-3/4 bg-full">
      <div className="md:col-span-12 text-center mt-8 col-span-4">
        <div className="relative md:mb-8 mb-2 h-20 w-full">
          <StaticImage className="lg:w-2/5 md:w-3/5 w-4/5 left-1/2 md:-top-2 top-0 transform -translate-x-1/2"
                       src={"../images/intro-text-bg.png"} alt={"Intro Text Bg"} style={{ position: "absolute" }} />
          <h1
            className="absolute md:text-4xl text-2xl font-bold top-0 left-1/2 transform -translate-x-1/2 w-full">ครีมกันแดดสูตรพิเศษ</h1>
          <p
            className="absolute md:top-16 top-12 text-sm z-20 left-1/2 transform -translate-x-1/2 md:w-full w-3/4">กันแดดสูตรพิเศษเฉพาะสำหรับสาวๆ
            ไม่คุมมัน ไม่อุดตัน และไม่ก่อให้เกิดสิว</p>
        </div>
      </div>
      <IntroItem
        img={<StaticImage
          className="w-full"
          src="../images/paraben-free.png"
          alt="Paraben Free"
        />}
        title={"ไม่มีพาราเบน"}
        subtitle={"อ่อนโยนกับทุกสภาพผิว"}
        delay={"100"}
      />
      <IntroItem
        img={<StaticImage
          className="w-full animate-fade-in-down"
          src="../images/alcohol-free.png"
          alt="Alcohol Free"
        />}
        title={"ไม่มีแอลกอฮอล์"}
        subtitle={"ต่อให้ผิวแแพ้ง่ายก็ใช้ได้"}
        delay={"300"}
      />
      <IntroItem
        img={<StaticImage
          className="w-full animate-fade-in-down"
          src="../images/non-toxic.png"
          alt="Non Toxic"
        />}
        title={"ไม่มีสารกันเสีย"}
        subtitle={"ไม่ก่อให้เกิดการระคายเคือง"}
        delay={"500"}
      />
      <IntroItem
        img={<StaticImage
          className="w-full animate-fade-in-down"
          src="../images/natural-cosmetic.png"
          alt="Natural Cosmetic"
        />}
        title={"ไม่มีสารอันตราย"}
        subtitle={"มั่นใจว่าปลอดภัยกับผิว"}
        delay={"700"}
      />
    </div>
  )
}

export default IntroSection