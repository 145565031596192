import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import GateSection from "../sections/gate"
import BuyButton from "../components/BuyButton"
import ReviewSection from "../sections/review"
// @ts-ignore
import title from "../images/sirisun-title.png"
// @ts-ignore
import product from "../images/product.png"
// @ts-ignore
import packaging from "../images/packaging.png"
// @ts-ignore
import DeliveryTruck from "../images/icon-delivery-truck.png"
// @ts-ignore
import video from "../images/NOON.mp4"
import IntroSection from "../sections/intro"

const IndexPage = () => (
  <Layout>
    {/* Section#1: Hero Banner */}
    <div className="lg:h-screen lg:w-screen bg-banner bg-cover bg-bottom h-auto">
      <div className="lg:grid lg:grid-cols-12 lg:gap-4 lg:gap-8 container mx-auto h-full grid-cols-4">
        <div className="lg:pt-0 lg:col-span-5 flex flex-col justify-center items-center pt-16 col-span-4">
          <img
            className="md:w-auto w-1/2"
            data-sal="slide-up"
            data-sal-duration="1000" // changes duration of the animation (from 200 to 2000 ms)
            data-sal-delay={"0"} // adds delay to the animation (from 5 to 1000 ms)
            data-sal-easing="ease"
            src={title}
            alt="Title"
          />
          <p className="lg:w-full lg:my-8 text-center my-4 w-10/12" data-sal="slide-up"
             data-sal-duration="1000" // changes duration of the animation (from 200 to 2000 ms)
             data-sal-delay={"400"} // adds delay to the animation (from 5 to 1000 ms)
             data-sal-easing="ease">
            กันแดดหน้าผ่องเกรดพรีเมียม เนื้อครีมบางเบา
            เกลี่ยง่าย ไม่เหนอะหนะ เหมาะกับทุกสภาพผิว
          </p>
          <div data-sal="slide-up"
               data-sal-duration="1000" // changes duration of the animation (from 200 to 2000 ms)
               data-sal-delay={"800"} // adds delay to the animation (from 5 to 1000 ms)
               data-sal-easing="ease">
            <BuyButton />
          </div>
        </div>
        <div
          className="lg:top-52 lg:col-span-7 lg:py-0 lg:h-1/2 lg:mt-0 md:h-96 md:mt-2 col-span-4 py-8 relative mt-4 h-72">
          <img
            className="bottom-12 absolute w-1/2"
            style={{ right: "5%" }}
            data-sal="slide-up"
            data-sal-duration="1000" // changes duration of the animation (from 200 to 2000 ms)
            data-sal-delay={"200"} // adds delay to the animation (from 5 to 1000 ms)
            data-sal-easing="ease"
            src={packaging}
            alt="Packaging"
          />
          <img
            className="bottom-12 absolute w-1/2"
            data-sal="slide-left"
            data-sal-duration="1000" // changes duration of the animation (from 200 to 2000 ms)
            data-sal-delay={"200"} // adds delay to the animation (from 5 to 1000 ms)
            data-sal-easing="ease"
            style={{ left: "5%" }}
            src={product}
            alt="Product"
          />
          <StaticImage
            className="w-3/4 md:bottom-0 bottom-6 animate-float"
            style={{ right: "5%", width: "25%", position: "absolute" }}
            src="../images/product-price.png"
            alt="Product Price"
          />
        </div>
      </div>
    </div>

    {/* Section#2: Intro */}
    <IntroSection />

    {/* Section#3: Door */}
    <GateSection />

    {/* Section#4: Reviewer  */}
    <ReviewSection />

    {/* Section#5: Influencer Gallery */}
    <div className="container mx-auto grid md:grid-cols-12 md:mt-0 px-4 mt-4 mb-4 grid-cols-6 gap-4">
      <div className="col-span-2 md:animate-float-v-down"
           data-sal="slide-down"
           data-sal-duration="1000" // changes duration of the animation (from 200 to 2000 ms)
           data-sal-delay={"0"} // adds delay to the animation (from 5 to 1000 ms)
           data-sal-easing="ease"
      >
        <StaticImage
          src="../images/influencer-1.png"
          alt="Influencer 1"
        />
      </div>
      <div className="col-span-2 md:pt-16 pt-0 md:animate-float-v-up"
           data-sal="slide-down"
           data-sal-duration="1000" // changes duration of the animation (from 200 to 2000 ms)
           data-sal-delay={"200"} // adds delay to the animation (from 5 to 1000 ms)
           data-sal-easing="ease"
      >
        <StaticImage
          src="../images/influencer-2.png"
          alt="Influencer 2"
        />
      </div>
      <div className="col-span-2 md:pt-32 pt-0 md:animate-float-v-up"
           data-sal="slide-down"
           data-sal-duration="1000" // changes duration of the animation (from 200 to 2000 ms)
           data-sal-delay={"400"} // adds delay to the animation (from 5 to 1000 ms)
           data-sal-easing="ease"
      >
        <StaticImage
          src="../images/influencer-3.png"
          alt="Influencer 3"
        />
      </div>
      <div className="col-span-2 md:pt-12 pt-0 md:animate-float-v-down"
           data-sal="slide-down"
           data-sal-duration="1000" // changes duration of the animation (from 200 to 2000 ms)
           data-sal-delay={"400"} // adds delay to the animation (from 5 to 1000 ms)
           data-sal-easing="ease">
        <StaticImage
          src="../images/influencer-4.png"
          alt="Influencer 4"
        />
      </div>
      <div className="col-span-2 md:pt-24 pt-0 md:animate-float-v-up"
           data-sal="slide-down"
           data-sal-duration="1000" // changes duration of the animation (from 200 to 2000 ms)
           data-sal-delay={"600"} // adds delay to the animation (from 5 to 1000 ms)
           data-sal-easing="ease">
        <StaticImage
          src="../images/influencer-5.png"
          alt="Influencer 5"
        />
      </div>
      <div className="col-span-2 md:pt-8 pt-0 md:animate-float-v-down"
           data-sal="slide-down"
           data-sal-duration="1000" // changes duration of the animation (from 200 to 2000 ms)
           data-sal-delay={"800"} // adds delay to the animation (from 5 to 1000 ms)
           data-sal-easing="ease">
        <StaticImage
          src="../images/influencer-6.png"
          alt="Influencer 6"
        />
      </div>
    </div>

    {/* Section#6: Promotion */}
    <div className="md:bg-promotion bg-promotion-mobile bg-cover">
      <div className="container mx-auto grid md:grid-cols-12 gap-4 grid-cols-4 md:pt-20 pt-24">
        <div
          className="col-span-6 flex flex-col items-center justify-center bg-promotion-text bg-y-20pc bg-no-repeat bg-contain">
          <h1 className="lg:text-5xl text-4xl mb-6 font-bold md:pt-0 pt-20"
              data-sal="zoom-in"
              data-sal-duration="800" // changes duration of the animation (from 200 to 2000 ms)
              data-sal-delay={"0"} // adds delay to the animation (from 5 to 1000 ms)
              data-sal-easing="ease"
          >โปรโมชั่นพิเศษ!</h1>
          <div className="flex flex-col mb-8 lg:w-3/5 md:w-full w-4/5 px-6">
            <h2 className="text-2xl mb-2"
                data-sal="slide-left"
                data-sal-duration="800" // changes duration of the animation (from 200 to 2000 ms)
                data-sal-delay={"200"} // adds delay to the animation (from 5 to 1000 ms)
                data-sal-easing="ease">5 กระปุกเพียง</h2>
            <h3 className="text-4xl self-end font-bold" data-sal="slide-left"
                data-sal-duration="1000" // changes duration of the animation (from 200 to 2000 ms)
                data-sal-delay={"400"} // adds delay to the animation (from 5 to 1000 ms)
                data-sal-easing="ease"><span className="text-5xl text-secondary-blue">950</span> บาท
            </h3>
            <h4 className="text-2xl self-end mt-2 relative" data-sal="slide-left"
                data-sal-duration="1000" // changes duration of the animation (from 200 to 2000 ms)
                data-sal-delay={"800"} // adds delay to the animation (from 5 to 1000 ms)
                data-sal-easing="ease">
              <StaticImage className="top-2" style={{ position: "absolute", width: "90%" }}
                           src="../images/text-line-through.png" alt="line trough" />
              1,450.-
            </h4>
          </div>
          <div data-sal="slide-up"
               data-sal-duration="1000" // changes duration of the animation (from 200 to 2000 ms)
               data-sal-delay={"1000"} // adds delay to the animation (from 5 to 1000 ms)
               data-sal-easing="ease">
            <BuyButton />
          </div>
          <p className="mt-8 flex items-center"
             data-sal="flip-down"
             data-sal-duration="1000" // changes duration of the animation (from 200 to 2000 ms)
             data-sal-delay={"1000"} // adds delay to the animation (from 5 to 1000 ms)
             data-sal-easing="ease"><img className="mr-2" src={DeliveryTruck}
                                                 alt={"Delivery Truck Icon"} />บริการจัดส่งฟรีและรับชำระเงินปลายทาง
          </p>
        </div>
        <div className="col-span-6 md:ml-0 col-span-4 text-center ml-16"
             data-sal="zoom-in"
             data-sal-duration="1000" // changes duration of the animation (from 200 to 2000 ms)
             data-sal-delay={"400"} // adds delay to the animation (from 5 to 1000 ms)
             data-sal-easing="ease"
        >
          <StaticImage
            src="../images/product-promotion.png"
            alt="Product Promotion"
          />
        </div>
      </div>
    </div>

    {/* Section#6: Video */}
    <video className="w-screen h-screen object-cover" src={video} loop autoPlay playsInline muted />
  </Layout>
)

export default IndexPage
