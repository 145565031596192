import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
// @ts-ignore
import ArrowLeft from "../images/icon-arrow-left.png"
// @ts-ignore
import ArrowRight from "../images/icon-arrow-right.png"
import Slider from "react-slick"

interface ReviewSectionProps {
}

const ReviewItem = ({ img, comment, name }) =>
  <div
    className="text-center flex flex-col items-center justify-center mx-auto md:w-1/2 w-full">
    {img}
    <p className="my-6 text">{comment}</p>
    <p className="mb-2 font-bold">{name}</p>
  </div>

const BackArrow = (props) => <div className="text-sm flex items-center absolute md:-top-20 top-0 cursor-pointer z-10"
                                  onClick={props.onClick}>
  <img src={ArrowLeft} className="mr-2 " alt={"Back Arrow"} width={16} height={16} />ก่อนหน้า</div>
const NextArrow = (props) => <div
  className="text-sm flex items-center absolute md:-top-20 top-0 right-0 cursor-pointer z-10"
  onClick={props.onClick}>ถัดไป <img
  src={ArrowRight}
  className="ml-2" alt={"Next Arrow"} width={16} height={16} />
</div>

const ReviewSection: React.FC<ReviewSectionProps> = ({}) => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <BackArrow />
  }
  return (
    <div className="container mx-auto grid grid-cols-12 gap-4 my-8 px-4">
      <div className="col-span-12 text-center mb-8 px-4">
        <StaticImage width={64} src={"../images/quote.png"} alt={"Quote"} />
        <h1 className="text-3xl font-bold mt-4">การันตีจากตัวแทนและลูกค้า</h1>
      </div>
      <div className={"col-span-12"}>
        <Slider {...settings}>
          <ReviewItem
            img={<StaticImage
              width={80}
              src="../images/reviewer-1.png"
              alt="Reviewer 1"
            />}
            comment={`“กันแดด และบำรุงในตัวเดียว Sirisun Sunscreen Cream 
            ตัวเลือกที่นุ่นเลือกใช้ค่ะ”`}
            name={"คุณนุ่น"}
          />
          <ReviewItem
            img={<StaticImage
              width={80}
              src="../images/reviewer-2.png"
              alt="Reviewer 2"
            />}
            comment={`“ครีมกันแดด Sirisun ทั้งปกป้อง และบำรุง สามารถกันแดดได้ถึง
            50 SPF PA++++ เลยค่ะ กันแดดดีมากมากเลยค่ะ”`}
            name={"คุณน้ำชา"}
          />
          <ReviewItem
            img={<StaticImage
              width={80}
              src="../images/reviewer-3.png"
              alt="Reviewer 3"
            />}
            comment={`“ไม่ต้องง้อรองพื้นอีกแล้วค่ะ แถมยังผิวสวย รักเลยค้าาา แถมยังกันน้ำดีม๊ากกก
            ตัวกันแดดไม่หลุดเลย ท้าแดดยังไงให้ผิวปังคะเนี่ย”`}
            name={"คุณบัว"}
          />
          <ReviewItem
            img={<StaticImage
              width={80}
              src="../images/reviewer-4.png"
              alt="Reviewer 4"
            />}
            comment={`“ชอบที่เนื้อครีมค่ะ นุ่มลื่นเกลี่ยง่ายมาก และก็ผิดเล่นแสงสวยมากเลยค่ะ
            และช่วยปรับผิวหน้าให้เรียบเนียนด้วยนะคะทุกคน”`}
            name={"คุณดรีม"}
          />
          <ReviewItem
            img={<StaticImage
              width={80}
              src="../images/reviewer-5.png"
              alt="Reviewer 5"
            />}
            comment={`“ใช้แล้วผิวสวย คุมมันดี แถมกันแดดดีมากๆ
            แต่ยังล้างออกง่าย ไม่อุดตัน เป็นสูตรลับเฉพาะของแบรนด์นี้เลยย”`}
            name={"คุณเจน"}
          />
        </Slider>
      </div>
    </div>
  )
}

export default ReviewSection