import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Slider from "react-slick"

const GateItem = ({ color, header, title, body, img, bgClassName, textImg }) => {
  return <div className={`${bgClassName} bg-cover bg-no-repeat bg-y-16 overflow-hidden`}>
    <div className="container mx-auto grid md:grid-cols-12 grid-cols-4 gap-4">
      <div className="md:col-span-6 col-span-4 text-center relative">
        <h1 className={`lg:text-4xl lg:top-16 text-3xl z-20 absolute top-10 w-full text-${color} font-bold`}>{header}</h1>
        {img}
      </div>
      <div
        className="md:col-span-6 md:justify-center md:h-auto col-span-4 text-center flex flex-col items-center justify-start bg-contain bg-no-repeat relative w-full h-80">
        <StaticImage
          className="lg:top-36 lg:w-4/5 md:top-20 md:w-full md:visible -top-5 invisible"
          style={{ position: 'absolute' }}
          src={"../images/gate-text-bg.png"}
          alt={"Gate Text BG"}
        />
        {textImg}
        <h2 className="text-3xl lg:mt-24 lg:mb-14 md:mt-24 mb-4 mt-3 w-full font-bold">{title}</h2>
        <p className="lg:w-3/4 w-4/5 z-20">{body}</p>
      </div>
    </div>
  </div>
}

const GateSection = () => {
  const settings = {
    dots: false,
    infinite: true,
    fade: true,
    speed: 750,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7500,
    pauseOnHover: false,
    arrows: false
  }
  return (
    <Slider {...settings}>
      <GateItem
        color={"bee-yellow"}
        header={"ประตูสู่ผิวสวย"}
        title={"Black bee ome"}
        body={`น้ำผึ้งจากผึ้งดำสายพันธุ์ที่หายากและเก่าแก่ มักอาศัยอยู่
          ในหุบเขาประเทศสวิสเซอร์แลนด์ มีคุณสมบัติช่วยปรับสมดุล
          ของน้ำมันบนในหน้าควบคุมความมัน ไม่ก่อให้เกิดการแพ้
          รวมถึงลดปัญหาสิวได้ทุกประเภท`
        }
        bgClassName={"bg-bee"}
        img={
          <StaticImage
            className="z-10"
            src={"../images/black-bee-ome.png"}
            alt={"Black Bee Ome"}
          />
        }
        textImg={
          <StaticImage
            className="w-1/3 lg:-right-2 md:-right-12 -right-2 bottom-2"
            style={{ position: 'absolute' }}
            src={`../images/beehive.png`}
            alt={"Bee Hive"}
          />
        }
      />
      <GateItem
        color={"secondary-purple"}
        header={"สกัดจากธรรมชาติ"}
        title={
          <>
            <p className="text-base font-light text-secondary-purple">สารสกัดสเต็มเซลล์</p>
            เถาองุ่นป่าฮอกไกโด
          </>
        }
        body={`สารสกัดจากเถาองุ่นป่าที่ขึ้นเองโดยธรรมชาติในฮอกไกโด
          มีคุณสมบัติพิเศษที่ช่วยในเรื่องของแอนตี้ออกซิเดนท์
          และยังมีสารต้านอนุมูลอิสระที่ช่วยฟื้นฟูเซลล์ผิวหนัง
          ให้มีสุขภาพดี ลบเลือนริ้วรอยแห่งวัย`
        }
        bgClassName={"bg-grape"}
        img={
          <StaticImage
            className="z-10"
            src={"../images/hokkaido-grape.png"}
            alt={"Hokkaido Grape"}
          />}
        textImg={
          <StaticImage
            className="w-1/4 lg:right-0 md:-right-10 right-0 bottom-2"
            style={{ position: 'absolute' }}
            src={`../images/grape.png`}
            alt={"Grape"}
          />
        }
      />
      <GateItem
        color={"secondary-blue"}
        header={"อ่อนโยนกับผิว"}
        title={
          <>
            Octocrylene
            <p className="text-base mx-2">and</p>
            <h3 className="text-2xl">Octymelthoxycinnamate</h3>
          </>
        }
        body={`สารสกัดที่สำคัญมากๆในครีมกันแดด ช่วยปกป้องผิว
          จากแสงแดดและรังสี UVA UVB ได้อย่างดี
          ไม่ทำให้เกิดอาการแพ้ เหมาะสำหรับทุกสภาพผิว`}
        bgClassName={"bg-octocrylene"}
        img={
          <StaticImage
            className="z-10"
            src={"../images/octocrylene.png"}
            alt={"Octocrylene"}
          />
        }
        textImg={
          <StaticImage
            className="w-1/4 lg:right-0 md:-right-10 right-2 bottom-2"
            style={{ position: 'absolute' }}
            src={`../images/octocrylene-molecule.png`}
            alt={"Octocrylene Molecule"}
          />
        }
      />
    </Slider>
  )
}

export default GateSection